import React from "react";
import Header from "../headerone/Main";
import Bredcom from "../Bredcom/Main";
// Import the PDF (adjust the relative path as needed)

function Main() {
  return (
    <>
      <Header />
      <Bredcom
        title="Home"
        subtitle="Affiliation"
        description={
          <>
            <section className="animations-100">
              Give instruction to a wise man, and he will be still wiser; teach
              a righteous man, and he will increase in learning
            </section>
          </>
        }
      />
      <section className="project-detail">
        <div className="container">
          <div className="lower-content">
            <div className="row" style={{ overflowX: "auto" }}>
              <div className="text-column col-lg-12 col-md-12 col-sm-12">
                <h3>MANDATORY PUBLIC DISCLOSURE</h3>
                <h5>A.GENERAL INFORMATION</h5>
                <div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Information</th>
                        <th scope="col">Detail</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>NAME OF THE SCHOOL</td>
                        <td>
                          EBENEZER MARCUS VIJOYA INTERNATIONAL SCHOOL CBSE
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>AFFILIATION NO</td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>SCHOOL CODE</td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>COMPLETE ADDRESS WITH PIN CODE</td>
                        <td>
                          NO.9,10 ASAMBUR & ATHIYUR VILLAGE, MANJAKUTTAI
                          PANCHAYAT, YERCAUD SALEM, SALEM , TAMILNADU - 636601
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td>PRINCIPAL NAME AND QUALIFICATION </td>
                        <td>Mrs. GAYATRI RAVINDRAN M.A.,B.ED</td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td>SCHOOL EMAIL ID </td>
                        <td>ebenezermarcusvijoyacbse@gmail.com</td>
                      </tr>
                      <tr>
                        <th scope="row">7</th>
                        <td>CONTACT DETAILS </td>
                        <td>+91-9952953300, +91-6369175830</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="text-column col-lg-12 col-md-12 col-sm-12">
                <h5 style={{ marginTop: "40px" }}>
                  B.DOCUMENTS AND INFORMATION :
                </h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">DOCUMENTS / INFORMATION</th>
                      <th scope="col">DOCUMENTS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>
                        COPIES OF AFFILIATION / UPGRADATION LETTER AND RECENT
                        EXTENSION OF AFFILIATION IF ANY
                      </td>
                      <td>
                      <a href="/annexures/Annexure 1.pdf" target="_blank" rel="noopener noreferrer">
                        Annexure 1
                      </a>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>
                        COPIES OF SOCIETIES / TRUST / COMPANY REGISTRATION /
                        RENEWAL CERTIFICATE, AS APPLICABLE
                      </td>
                      <td>
                      <a href="/annexures/Annexure 2.pdf" target="_blank" rel="noopener noreferrer"> 
                          Annexure 2
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>
                        COPIES OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF
                        APPLICABLE BY THE STATE GOVT / UT
                      </td>
                      <td>
                      <a href="/annexures/Annexure 3.pdf" target="_blank" rel="noopener noreferrer">
                          Annexure 3
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>
                        COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009
                        AND ITS RENEWAL IF APPLICABLE
                      </td>
                      <td>
                      <a href="/annexures/Annexure 4.pdf" target="_blank" rel="noopener noreferrer">
                          Annexure 4
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>
                        COPY OF VALID BUILDING CERTIFICATE AS PER THE NATIONAL
                        BUILDING CODE
                      </td>
                      <td>
                      <a href="/annexures/Annexure 5.pdf" target="_blank" rel="noopener noreferrer">
                          Annexure 5
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">6</th>
                      <td>
                        COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE
                        COMPETENT AUTHORITY
                      </td>
                      <td>
                      <a href="/annexures/Annexure 6.pdf" target="_blank" rel="noopener noreferrer">
                          Annexure 6
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">7</th>
                      <td>
                        COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR
                        AFFILIATION / UPGRADATION / EXTENSION OF AFFILIATION OR
                        SELF CERTIFICATION BY SCHOOL
                      </td>
                      <td>
                      <a href="/annexures/Annexure 7.pdf" target="_blank" rel="noopener noreferrer">
                          Annexure 7
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">8</th>
                      <td>
                        COPIES OF WATER, HEALTH AND SANITATION CERTIFICATES
                      </td>
                      <td>
                      <a href="/annexures/Annexure 8.pdf" target="_blank" rel="noopener noreferrer">
                          Annexure 8
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="text-column col-lg-12 col-md-12 col-sm-12">
                <h5 style={{ marginTop: "40px" }}>C.RESULTS AND ACADEMICS</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">DOCUMENTS / INFORMATION</th>
                      <th scope="col">DOCUMENTS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>FEE STRUCTURE OF THE SCHOOL (PER MONTH)</td>
                      <td>
                      <a href="/annexures/Annexure 9.pdf" target="_blank" rel="noopener noreferrer">
                          Annexure 9
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>ANNUAL ACADEMIC CALENDER Annexure 10</td>
                      <td>
                      <a href="/annexures/Annexure 10.pdf" target="_blank" rel="noopener noreferrer">
                          Annexure 10
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>
                        LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC) Annexure 11
                      </td>
                      <td>
                      <a href="/annexures/Annexure 11.pdf" target="_blank" rel="noopener noreferrer">
                          Annexure 11
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>
                        LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS
                      </td>
                      <td>
                      <a href="/annexures/Annexure 12.pdf" target="_blank" rel="noopener noreferrer">
                          Annexure 12
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>
                        LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER
                        APPLICABILITY
                      </td>
                      <td>
                      <a href="/annexures/Annexure 13.pdf" target="_blank" rel="noopener noreferrer">
                          Annexure 13
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="text-column col-lg-12 col-md-12 col-sm-12">
                <h5 style={{ marginTop: "40px" }}>D.STAFF (TEACHING)</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">INFORMATION</th>
                      <th scope="col">DETAILS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>PRINCIPAL</td>
                      <td>Mrs. GAYATRI RAVINDRAN</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>TOTAL NO. OF TEACHERS</td>
                      <td>06</td>
                    </tr>
                    <tr>
                      <th scope="row"></th>
                      <td>PGT</td>
                      <td>NA</td>
                    </tr>
                    <tr>
                      <th scope="row"></th>
                      <td>TGT</td>
                      <td>03</td>
                    </tr>
                    <tr>
                      <th scope="row"></th>
                      <td>PRT</td>
                      <td>02</td>
                    </tr>
                    <tr>
                      <th scope="row"></th>
                      <td>NTT</td>
                      <td>01</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>TEACHERS SECTION RATIO</td>
                      <td>1:5</td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>DETAILS OF SPECIAL EDUCATOR</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>DETAILS OF COUNSELLOR AND WELLNESS TEACHER</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="text-column col-lg-12 col-md-12 col-sm-12">
                <h5 style={{ marginTop: "40px" }}>E.SCHOOL INFRASTRUCTURE</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">INFORMATION</th>
                      <th scope="col">DETAILS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>TOTAL CAMPUS AREA OF THE SCHOOL (IN SQ. MTR)</td>
                      <td>31848.76</td>
                    </tr>
                    <tr>
                    <th scope="row">2</th>
                    <td>NO. AND SIZE OF THE CLASS ROOMS (IN SQ. MTR)</td>
                    <td colSpan="2">
                      21 CLASS ROOMS<br />
                      23' X 19'6"
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td style={{ whiteSpace: "nowrap" }}>
                      NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS (IN SQ. MTR)
                    </td>
                    <td style={{ padding: "0.25rem" }}>
                      <table
                        style={{
                          width: "100%",
                          borderCollapse: "collapse",
                          fontSize: "0.9rem",
                        }}>
                        <tbody>
                        <tr>
                            <td>Total - 03</td>
                          </tr>
                          <tr>
                            <td>PHY LAB – 28’ X 32’</td>
                          </tr>
                          <tr>
                            <td>CHEM LAB – 30’ X 24’</td>
                          </tr>
                          <tr>
                            <td>BIO LAB – 28’ X 32’</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>INTERNET FACILITY</td>
                      <td>YES</td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>NO. OF GIRLS TOILETS</td>
                      <td>08</td>
                    </tr>
                    <tr>
                      <th scope="row">6</th>
                      <td>NO. OF BOYS TOILETS</td>
                      <td>08</td>
                    </tr>
                    <tr>
                      <th scope="row">7</th>
                      <td>
                        LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL
                        COVERING THE INFRASTURCTURE OF THE SCHOOL
                      </td>
                      <td>
                        <a
                          href="https://youtu.be/YUywpde8aB8?si=p_TGLoHIwG-X2Kg6"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://youtu.be/YUywpde8aB8?si=p_TGLoHIwG-X2Kg6
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Main;
